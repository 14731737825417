export const locale = {
    lang: 'en',
    data: {
        'MFA': {
            'TITLE': '2-step verification (MFA)'
        },
        'NAV': {
            'DASHBOARDS': 'Dashboards',
            'ADMIN': 'Admin',
            'REPORTE': 'Report',
            'BALANCEV2': {
                'TITULO': 'Balance',
                'PAYINS': 'Payins Settlement Balance '
            },
            'PAYMENT_LINK': 'Payment Link',
            'CHAT': 'Chat',
            'CONTACTS': 'Contacts',
            'DASH': {
                'RESUMEN': 'Summary',
            },
            'REPORT': {
                'CONSULTA': 'Consultation of operations',
                'TRANSACCION': 'Transaction report',
                'OPERATIVO': 'Operative report',
                'ACTUALIZACION': 'MASSIVE UPDATE',
                'ARCHIVOS': 'File Report',
                'EXTENDIDO': 'Settlement Report',
            },
            'ADM': {
                'USUARIOS': 'List of Users',
                'MERCHANT': 'merchant data',
                'COMERCIOS': 'List of Merchants',
                'GRUPOS': 'List of Groups',
                'PUSHPAYS': 'Module Push Pays',
                'TARIFAS': 'Rates Configuration',
                'TARIFAS_NUEVO': 'Rates Configuration NEW',
                'RUTEO': 'Route',
                'TRASLADO': 'Cash Transfer - ARG',
                'TRASLADO_SUBTITLE': 'List of cash transfer rates by processor',
                'SUBMENU_CONVERSION': 'Conversion USD',
            }
        },

        'WIDGET_SUPPORT': {
            'WELCOME_MESSAGE': 'Welcome to Monnet support services',
            'CONTACT_US': 'Contact us',
            'NAME': 'Name',
            'EMAIL': 'Email',
            'SUBJECT': 'Subject',
            'DESCRIPTION': 'Description',
            'MAX_FILE_SIZE_MESSAGE': 'The file exceeds the maximum size allowed (5MB)',
            'MAX_FILE_QUANTITY_MESSAGE': 'The maximum number of files allowed is 5',
            'SUPPORT': 'Support',
            'SENDING': 'Sending request...',
            'SEND': 'Send',
            'MAX_FILE_INFO': 'Upload files (max 5)',
            'FILE_CLICK': 'Click here to add any file',
            'COMPLETE_REQUIRED_FIELD': 'Complete fields requirements',
            'SUCCESS_MESSAGE': 'Your request has been sent successfully',
            'ERROR_MESSAGE': 'An error occurred while sending your request',
            'FILE_ALLOWED': 'Allowed files',
            'ATACHED_FILES': 'Attached files',
        },


        'ELEM_GENERIC': {
            'BUSCAR': 'Search',
            'LIMPIAR': 'Clear',
            'AGREGAR': 'Add',
            'BUSCAR_COMERCIO': 'Search Merchant',
            'BUSCAR_GRUPO': 'Search group',
            'TARIFAS': 'Search rates',
            'COMERCIO': 'Search merchant',
            'ACTIVAR': 'Activate',
            'ELIMINAR': 'Delete',
            'CANCELAR': 'Cancel',
            'ACTUALIZAR': 'Update',
            'CONFIRMAR': 'Confirm',
            'EDITAR': 'Edit',
            'REQUIRED_FIELD': 'Complete field requirements',
            'CERRAR': 'Close',
            'MONEDA': 'Currency',
            'COMPLETE_REQUIRED_FIELD': 'Complete required field',
            'NO_DATA_EXPORT': 'No data to export',
            'NO_DATA_FOUND': 'No data found',
            'DESACTIVAR': 'Deactivate',
            'CONFIGURAR': 'Set up',
            'TASAS_CAMBIO': 'Exchange Rates',
            'AGREGAR_TASA': 'Add Rate',
            'GUARDAR': 'Save',
            'GENERAR': 'Generate',
            'FECHA_INI': 'Start Date',
            'REGISTRAR': 'Register',
            'FECHA_FIN': 'End Date',
            'ESTADO': 'Status',
            'FECHA_PAGO': 'Payment date',
            'FECHA_ATORIZACION': 'Autorization date',
            'DESCARGAR_EXCEL': 'Download Excel',
            'DESCARGAR_CSV': 'Download Csv',
            'FILTRO_DATOS': 'Data filter',
            'ESPERE': 'Please wait ...',
            'ELEGIR_COMERCIO': 'Choose Commerce',
            'ACCION': 'Action',
            'ERROR_3MESES': 'Must enter a 90 days time period',
            'ERROR_UNEXPECTED': 'Unexpected error',
            'ERROR_EMPTY': 'No records found within this dates',
            'ACTIVATE_MULTIPROCESSOR_TO_CONTINUE': 'Activate multi-processor to configure',
            'ACTIVATE_CONNECTION_TO_CONTINUE': 'Activate direct connections to configure',
            'PAYMENT_METHOD_CONFIGURATION_MESSAGE': 'Payment methods not found',
            'PROCESSOR_CONFIGURATION_MESSAGE': 'Processors not found',
            'CONFIGURATE_PAYMENT_METHOD': 'Configure payment methods',
            'EDIT_CONFIGURATE_PAYMENT_METHOD': 'Edit payment methods configuration',
            'EDIT_CONFIGURATE_DIRECT_CONNECTIONS': 'Edit Collector/Processor configuration',
            'CONTRACARGO': "Chargeback"
        },

        'DASHBBOARD': {
            'CANT_TOTAL_TRANS': 'Total Number of Transactions',
            'MONTO_TOTAL_TRANS': 'Total Amount of Transactions',
            'MONTO_TRANSACCIONES_SEMANA': 'Amount of Transactions per Day (last 7 days)',
            'MONTO_TOTAL_TRANS_CANAL': 'Total Amount of Transactions per Channel',
            'MONTO_TOT_SEMANA': {
                'TX': '#Tx:',
                'TX_PROM': '#Ticket Prom:',
                'TX_COMPLETADO': '#Tx. Completed:'
            }
        },

        'REPORTE': {
            'BASE': {
                'TITULO': 'Report',
                'TABLE_COMERCIO': 'Merchant',
                'TABLE_ORIGEN': 'Origin',
                'TABLE_ID': 'Operation Id',
                'TABLE_ID_COMERCIO': 'Merchant Operation Id',
                'TABLE_MONEDA': 'Currency',
                'TABLE_MONTO': 'Amount',
                'TABLE_MONTO_ORI': 'Original Amount',
                'TABLE_PROCESADOR': 'Payment Processor',
                'TABLE_REDPAGO': 'Payment Network',
                'TABLE_FECHA_REG': 'Registration date / time',
                'TABLE_FECHA_LIQU': 'Liquidation date',
                'TABLE_FECHA_COMP': 'Compensation date',
                'TABLE_ESTADO': 'Status',
                'TABLE_ERROR_DETAIL': 'Transaction info',
                'TABLE_CANAL': 'Payment Channel',
                'TABLE_FECHA_PAG': 'Registry Date / time',
                'TABLE_FECHA_AUT': 'Autorization Date / time',
                'TABLE_FECHA_PUSH': 'PushPay Date / time',
                'TABLE_TARIFA': 'Fee',
                'TABLE_IMPUESTO': 'Tax',
                'TABLE_FEE_AND_TAX': 'Total Fee & Tax',
                'TABLE_NOMBRE': 'Customer Name',
                'TABLE_NAME': 'Name',
                'TABLE_LAST_NAME': 'Last Name',
                'TABLE_LAST_EMAIL': 'Email',
                'TABLE_LASPAN': 'Last Pan',
                'TABLE_BANCO': 'Bank',
                'TABLE_CARD_TYPE': 'Card Type',
                'TABLE_BRAND': 'Brand',
                'TABLE_INSTALLMENT': 'Quotas',
                'TABLE_NET_MERCHANT': 'Net to Merchant',
                'TABLE_PROCESSING_TYPE': 'Processing Type',
                'TABLE_NOTIFICATION_STATUS': 'Notification Status',
                'TABLE_ID_CLIENTE': 'Merchant Client Id',
                'TABLE_PAYMENT_CODE': 'Payment Code',
                'TABLE_TICKET_NUMBER': 'Ticket number',
                'TABLE_PROCESSOR_ID': 'Processor Id',
                'TABLE_IS_CHARGEBACK': 'Chargeback'
            },
            'BASE_EXPORT_FILE': {
                'FILE_NAME': 'Report',
                'SHEET_NAME_OPERATIVE': 'Operative report',
                'SHEET_NAME_TRANSACTION': 'Transaction report',
                'COLUMN_COMERCIO': 'Merchant',
                'COLUMN_ORIGEN': 'Origin',
                'COLUMN_ID': 'Operation Id',
                'COLUMN_ID_COMERCIO': 'Merchant Operation Id',
                'COLUMN_MONEDA': 'Currency',
                'COLUMN_MONTO': 'Amount',
                'COLUMN_MONTO_ORI': 'Original Amount',
                'COLUMN_PROCESADOR': 'Payment Processor',
                'COLUMN_FECHA_REG': 'Registration date / time',
                'COLUMN_ESTADO': 'Status',
                'COLUMN_ERROR_DETAILS':'Transaction info',
                'COLUMN_CANAL': 'Payment Channel',
                'COLUMN_FECHA_PAG': 'Payment date / time',
                'COLUMN_FECHA_AUT': 'Autorization Date / time',
                'COLUMN_FECHA_LIQU': 'Liquidation Date',
                'COLUMN_FECHA_COMP': 'Compensation Date',
                'COLUMN_FECHA_PUSH': 'PushPay Date / time',
                'COLUMN_TARIFA': 'Fee',
                'COLUMN_IMPUESTO': 'Tax',
                'COLUMN_NOMBRE': 'Customer Name',
                'COLUMN_NAME': 'Name',
                'COLUMN_LAST_NAME': 'Last Name',
                'COLUMN_LAST_EMAIL': 'Email',
                'COLUMN_LASPAN': 'Last Pan',
                'COLUMN_BANK': 'Bank',
                'COLUMN_CARD_TYPE': 'Card Type',
                'COLUMN_BRAND': 'Brand',
                'COLUMN_INSTALLMENT': 'Quotas',
                'COLUMN_NET_MERCHANT': 'Net to Merchant',
                'COLUMN_PROCESSING_TYPE': 'Processing Type',
                'COLUMN_NOTIFICATION_STATUS': 'Notification Status',
                'COLUMN_ID_CLIENTE': 'Merchant Client Id',
                'COLUMN_PAYMENT_CODE': 'Payment Code',
                'COLUMN_TICKET_NUMBER': 'Ticket number',
                'COLUMN_PROCESSOR_ID': 'Processor Id',
                'COLUMN_FEE_AND_TAX': 'Total Fee & Tax',
                'COLUMN_CHARGEBACK': 'CHARGEBACK'
            },
            'BASE_EXPORT_OPERATIVE_FILE': {
                'FILE_NAME': 'Operative report',
                'SHEET_NAME_OPERATIVE': 'Operative report',
                'COLUMN_COMERCIO': 'MERCHANT',
                'COLUMN_ORIGEN': 'ORIGIN',
                'COLUMN_ID': 'ID_OPERATION',
                'COLUMN_ID_COMERCIO': 'ID_OPERATION_MERCHANT',
                'COLUMN_MONEDA': 'CURRENCY',
                'COLUMN_MONTO': 'AMOUNT',
                'COLUMN_MONTO_ORI': 'ORIGINAL AMOUNT',
                'COLUMN_PROCESADOR': 'COD_PROCESSOR',
                'COLUMN_REDPAGO': 'PAYMENT_NETWORK',
                'COLUMN_FECHA_REG': 'REGISTRATION_DATE',
                'COLUMN_ESTADO': 'STATE',
                'COLUMN_ERROR_DETAILS': 'Transaction info',
                'COLUMN_CANAL': 'CHANNEL',
                'COLUMN_FECHA_PAG': 'PAYMENT_DATE',
                'COLUMN_FECHA_AUT': 'AUTHORIZATION_DATE',
                'COLUMN_FECHA_LIQU': 'LIQUIDATION_DATE',
                'COLUMN_FECHA_COMP': 'COMPENSATION_DATE',
                'COLUMN_FECHA_PUSH': 'PUSHPAY_DATE',
                'COLUMN_TARIFA': 'RATE',
                'COLUMN_IMPUESTO': 'TAX',
                'COLUMN_NAME': 'NAME',
                'COLUMN_LAST_NAME': 'LAST_NAME',
                'COLUMN_LAST_EMAIL': 'MAIL',
                'COLUMN_LAST_PAN': 'LAST_PAN',
                'COLUMN_CARD_TYPE': 'CARD_TYPE',
                'COLUMN_BRAND': 'BRAND',
                'COLUMN_INSTALLMENT': 'QUOTAS',
                'COLUMN_NET_MERCHANT': 'NET_MERCHANT',
                'COLUMN_PROCESSING_TYPE': 'PROCESSING_TYPE',
                'COLUMN_NOTIFICATION_STATUS': 'NOTIFICATION_STATE',
                'COLUMN_PAYMENT_CODE': 'Payment Code',
                'COLUMN_PROCESSOR_ID': 'Processor Id',
                'COLUMN_CHARGEBACK': 'CHARGEBACK'
            }
        },
        'ADMIN': {
            'LISTA_USUARIOS': {
                'TITULO': 'Users',
                'SUBTITULO': 'User List',
                'TABLE_NOM_USUARIO': 'User Name',
                'TABLE_NOMBRE': 'Name',
                'TABLE_APELLIDO': 'Surname',
                'TABLE_CORREO': 'Email',
                'TABLE_ROL': 'Role',
                'TABLE_ESTADO': 'Status',
                'TABLE_ACCIONES': 'Actions',
                'MODAL': {
                    'TITULO_NUEVO_USU': 'New User',
                    'TITULO_MODIF_USU': 'Edit User',
                    'NOMBRE_DE_USU': 'Username *',
                    'CONTRASENA': 'Password *',
                    'CORREO_USU': 'Email *',
                    'NOMBRES_USU': 'Names *',
                    'APELLIDOS_USU': 'Surname *',
                    'DOCUMENTO_USU': 'Document ',
                    'NRO_DOCUMENTO_USU': 'Document No. ',
                    'ELEGIR_COMERCIO_USU': 'Choose Commerce *',
                    'ROL_USU': 'Role *',
                    'GUARDAR_USU': 'Save',
                    'MODIFICAR_USU': 'Modify'
                }
            },
            'COMERCIO': {
                'TITULO': 'Merchant',
                'SUBTITULO': 'Data Merchant',
                'LLAVE_SHA': 'Key',
                'URL_RESPUESTA': 'Notification URL',
                'LISTA': {
                    'TABLA_ID': 'ID',
                    'TABLA_TIPO': 'Type',
                    'TABLA_KEY': 'Key',
                    'TABLA_ESTADO': 'State',
                    'TABLA_FECHA': 'Registration date',

                }
            },
            'COMERCIOS': {
                'TITULO': 'Merchants',
                'SUBTITULO': 'Merchant List',
                'LISTA': {
                    'TABLA_ID': 'ID',
                    'TABLA_NAME': 'Name',
                    'TABLA_ESTADO': 'State',
                    'TABLA_EMAIL': 'Email',
                    'TABLA_PAIS': 'Country',
                    'CONFIG_MULTIPROCESSOR': 'Multi-processor configuration',
                    'CONFIG_CONECTION': 'Processor/Collector configuration',
                    'TABLA_GRUPO': 'Group',
                    'TABLA_URL': 'Url',
                    'TABLA_FECHA': 'Registration Date'
                }
            },
            'GRUPOS': {
                'TITULO': 'Groups',
                'SUBTITULO': 'Group List',
                'MODAL': {
                    'TITULO_NUEVO_GRUPO': 'New User',
                    'TITULO_EDITAR_GRUPO': 'Edit Grupo',
                    'NOMBRE_GRUPO': 'Group Name',
                    'GUARDAR_GRUPO': 'Save',
                    'MODIFICAR_GRUPO': 'Modify'
                },
                'LISTA': {
                    'TABLA_ID': 'ID',
                    'TABLA_NAME': 'Name',
                    'TABLA_FECHA': 'Registration Date'
                }
            },

            'PUSHPAYS': {
                'BASE': {
                    'TITULO': 'Module Push Pay',
                    'SUBTITULO': 'Push Pay List',
                    'APROBACION_TITULO': 'Push Pay Approval',
                    'TABLE_COMERCIO': 'Merchant',
                    'TABLE_IDPUSH': 'Push-Pay Id',
                    'TABLE_ID': 'Operation Id',
                    'TABLE_ID_COMERCIO': 'Merchant Operation Id',
                    'TABLE_MONEDA': 'Currency',
                    'TABLE_MONTO': 'Amount',
                    'TABLE_MONTO_ORI': 'Original Amount',
                    'TABLE_PROCESADOR': 'Payment Processor',
                    'TABLE_FECHA_REG': 'Registration date / time',
                    'TABLE_FECHA_PUR': 'Purchase date / time',
                    'TABLE_ESTADO': 'Status Push Pay',
                    'TABLE_ESTADO2': 'Status',
                    'TABLE_ESTADO_ANTERIOR': 'Previous Status',
                    'TABLE_ESTADO_NUEVO': 'New Status',
                    'TABLE_CANAL': 'Payment Channel',
                    'TABLE_FECHA_PAG': 'Payment Date',
                    'TABLE_TARIFA': 'Fee',
                    'TABLE_IMPUESTO': 'Tax',
                    'TABLE_NOMBRE': 'Customer Name',
                    'TABLE_USUARIO': 'User',
                    'ACCIONES': {
                        'PUSH': 'Push-Pay',
                        'NOTIFICAR': 'Notify',
                        'EJECUTAR_PUSH': 'Run Push-Pay',
                        'VER_PUSHPAYS': 'Watch Push-Pays',
                        'APROBAR': 'Approve',
                        'RECHAZAR': 'Reject',
                        'VOLVER': 'Back',
                    }
                },
                'EXPORT': {
                    'SHEET_NAME_OPERATIVE': 'Push Pays report',
                    'COLUMN_COMERCIO': 'Merchant',
                    'COLUMN_IDPUSH': 'Push-Pay Id',
                    'COLUMN_ID': 'Operation Id',
                    'COLUMN_ID_COMERCIO': 'Merchant Operation Id',
                    'COLUMN_MONEDA': 'Currency',
                    'COLUMN_MONTO': 'Amount',
                    'COLUMN_FECHA_REG': 'Registration date / time',
                    'COLUMN_FECHA_PUR': 'Purchase date / time',
                    'COLUMN_ESTADO': 'Status',
                    'COLUMN_ESTADO_ANTERIOR': 'Previous Status',
                    'COLUMN_ESTADO_NUEVO': 'New Status',
                    'COLUMN_NOMBRE': 'Reason / Detail',
                    'COLUMN_USUARIO': 'User'
                },
                'MODAL': {
                    'TITULO': 'Push-Pay',
                    'DESCRIPCION': 'Reason / Detail',
                    'ESTADO': 'State',
                    'COMERCIO': 'Merchant',
                    'ID_COMERCIO': 'Merchant Operation Id',
                    'ID': 'Operation Id',
                    'MONTO': 'Amount',
                    'MONEDA': 'Currency',
                    'FECHA_REG': 'Registration date / time',
                }
            },
            'TARIFAS': {
                'TITULO': 'Rates',
                'SUBTITULO': 'Rates List',
                'MERCHANT': 'Merchant',
                'PAYMENT_METHOD': 'Payment Method',
                'CURRENCY': 'Currency',
                'VAR_RATE': 'Variable Rate',
                'MIN_RATE': 'Minumum Rate',
                'TAX': 'Tax',
                'FIXED_TASK': 'Fixed task',
                'ADITIONAL_COST': 'Aditional Cost',
                'STATUS': 'Status',
                'SAVE': 'Save',
                'CANCEL': 'Cancel',
                'NEW': 'New',
                'UPDATE': 'Update'
            },
            'TARIFAS_TABLA':{
                'TITLE': 'Rates',
                'MERCHANT': 'Merchant',
                'PAYMENT_METHOD': 'Payment Method',
                'CURRENCY': 'Currency',
                'TAX': 'Tax',
                'TIERS': 'Tiers',
                'USD': 'USD',
                'STATUS': 'Status',
                'ENABLE': 'Enable/Disable',
                'ACTION': 'Action',
                'PAGINATOR': 'Rates per page',
            },
            'RUTEO': {
                'TITULO': 'Routing',
                'PAIS': 'Country',
                'RAZON': 'Business name',
                'TIPO': 'Route type',
                'REGLA': 'Rule',
                'TABLE_REGLA': 'Rules',
                'TABLE_ESTADO': 'State',
                'TABLE_ACCION': 'Acion',
                'TABLE_METODO_PAGO': 'Payment method',
                'RULES_ASSIGNMENT': 'Rules assignment',
                'MULTIPROCESSOR': 'Multi-processor',
                'TABLE_NRO_PROCESADORES': 'Number of processors',
                'PAYMENT_COLLECTOR': 'Colector de pago',
                'CONFIGURAR': {
                    'TITLE': 'Configuration',
                    'REGLA': 'Rule',
                    'MIN_PROCESADORES': 'Minimum Processors',
                    'MAX_PROCESADORES': 'Maximum Processors',
                    'GUARDAR': 'Save',
                    'CANCELAR': 'Cancel',
                    'PROCESADORA': 'Processor',
                    'MONEDA': 'Currency',
                    'DISTRIBUCION_PORCENTAJE': {
                        'TITLE': 'Parameter settings',
                        'PORCENTAJE': 'Percentage%'
                    },
                    'DISTRIBUCION_AMOUNT_TRX': {
                        'TITLE': 'Parameter settings',
                        'MONTO_MINIMO': 'Minimum Amount',
                        'MONTO_MAXIMO': 'Maximum Amount'
                    },
                    'DISTRIBUCION_HORA': {
                        'TITLE': 'Parameter settings',
                        'HORA_INICIO': 'Start Time',
                        'HORA_FIN': 'End Time'
                    },
                    'DISTRIBUCION_MONTO_ACUMULADO': {
                        'TITLE': 'Parameter settings',
                        'MONTO_MAXIMO': 'Maximum Amount',
                        'PRIORIDAD': 'Priority'
                    }
                }
            },
            'LINK_DE_PAGOS': {
                'TITULO': 'Payment Link',
                'SUBTITULO': 'Generate Payment Link',
                'TITULO_CLIENTE': 'Customer Data',
                'TITULO_PAGO': 'Payment Data',
                'TITULO_METODO': 'Payment Method',
                'TITULO_RESUMEN': 'Summary',
                'GENERACION':{
                    'TITULO': 'Payment Link Generation',
                    'NOMBRE': 'Name',
                    'APELLIDO': 'Last Name',
                    'TIPO_DOC': 'Document Type',
                    'NRO_DOC': 'Document Number',
                    'EMAIL': 'Email',
                    'MONTO': 'Amount',
                    'MERCHANT_ID': 'Merchant ID',
                    'MONEDA': 'Currency',
                    'METODO_PAGO': 'Payment Method',
                    'FECHA': 'Date',
                    'OPERACION_ID': 'Operation ID',
                    'TIEMPO_EXPIRACION': 'Expiration Time (minutes)',
                    'DESCRIPCION': 'Description',
                    'GENERAR': 'Generate Link',
                    'CANCELAR': 'Cancel',
                    'ERROR_HEADER': 'Generation Error',	
                    // 'ERROR_TITLE': 'The payment link has not been generated, please check the following details:'
                    'ERROR_TITLE': 'An error occurred, please try again later...'
                },
                'CONFIGURACION':{
                    'TITULO': 'Configuration of Payment Links',
                    'SUBTITULO': 'Configuration',
                    'PAYIN_TRANSACTION_OK_URL': 'Transaction OK URL',
                    'PAYIN_TRANSACTION_ERROR_URL': 'Transaction Error URL',
                    'PAYIN_LANGUAGE': 'Language',
                    'PAYIN_CUSTOMER_PHONE': '   Customer Phone',
                    'PAYIN_CUSTOMER_ADDRESS': '  Customer Address',
                    'PAYIN_CUSTOMER_CITY': '    Customer City',
                    'PAYIN_CUSTOMER_REGION': '  Customer Region',
                    'PAYIN_CUSTOMER_COUNTRY': ' Customer Country',
                    'PAYIN_CUSTOMER_ZIP_CODE': '    Customer Zip Code',
                    'PAYIN_CUSTOMER_SHIPPING_PHONE': '  Customer Shipping Phone',
                    'PAYIN_CUSTOMER_SHIPPING_ADDRESS': '    Customer Shipping Address',
                    'PAYIN_CUSTOMER_SHIPPING_CITY': '   Customer Shipping City',
                    'PAYIN_CUSTOMER_SHIPPING_REGION': ' Customer Shipping Region',
                    'PAYIN_CUSTOMER_SHIPPING_COUNTRY': '    Customer Shipping Country',
                    'PAYIN_CUSTOMER_SHIPPING_ZIP_CODE': '   Customer Shipping Zip Code',
                    'PAYIN_REGULAR_CUSTOMER': ' Regular Customer',
                    'PAYIN_DISCOUNT_COUPON': '  Discount Coupon',
                    'PAYIN_FILTER_BY': '    Filter By',
                    'PAYIN_PRODUCT_ID': '   Product ID',
                    'PAYIN_PRODUCT_DESCRIPTION': '  Product Description',
                    'PAYIN_PRODUCT_AMOUNT': '   Product Amount',
                    'PAYIN_PRODUCT_SKU': '  Product SKU',
                    'PAYIN_PRODUCT_QUANTITY': ' Product Quantity',
                    'MERCHANT_NAME': '  Merchant Name',
                    'SAVE': 'Save configuration',
                    'ERROR_HEADER': 'Configuration Error',
                    'ERROR_TITLE': 'The configuration has not been saved, please check the following details:'
                },
                'TABLE': {
                    'TITULO': 'List of Generated Links',
                    'FECHA_GENERACION': 'Generation Date',
                    'USUARIO': 'User',
                    'ID_OPERACION':  'Operation ID',
                    'MONTO': 'Amount',
                    'MONEDA': 'Currency',
                    'ESTADO': 'Status',
                    'NOMBRE': 'Client name',
                    'APELLIDO': 'Client last name',
                    'EMAIL': 'Email',
                    'LINK_DE_PAGO': 'Payment Link',
                    'ARCHIVO': 'Report payment links',
                },
                'PROCESAMIENTO':{
                    'TITULO': 'Massive processing',
                },
                
            },
            'TRASLADO': {
                'CREAR': 'new registration',
                'VOLVER': 'Back',
                'TITULO': 'Cash Transfer',
                'PROCESADORA_SELECT': 'Select the processor to configure the payment network',
                'GUARDAR_REDES': 'Save Payment Networks',
                'TABLA_PROCESADORAS':{
                    'NOMBRE': 'Processor Name',
                    'FECHA': 'Creation Date',
                    'ACCIONES': 'Actions',
                    'EDITAR': 'Edit',
                    'VISUALIZAR': 'View',
                },
                'TABLA_TRASLADO':{
                    'ID_REGISTRO': 'Record ID',
                    'CODIGO_RED': 'Network Code',
                    'NOMBRE_RED': 'Network Name',
                    'TIPO': 'Type',
                    'FECHA_CREACION': 'Creation Date',
                    'TARIFA': 'Rate',
                    'ACCIONES': 'Actions',
                },
                'FORMULARIO':{
                    'TITULO_CREAR': 'Create cash transfer',
                    'TITULO_EDITAR': 'Edit cash transfer',
                    'TITULO_VISUALIZAR': 'View cash transfer',
                    'ID_REGISTRO': 'Record ID',
                    'CODIGO_RED': 'Network Code',
                    'NOMBRE_RED': 'Network Name',
                    'TIPO': 'Type',
                    'TARIFA': 'Rate',
                    'BOTON_GUARDAR': 'Add',
                    'BOTON_CANCELAR': 'Cancel',
                    'BOTON_EDITAR': 'Modify',
                    'BOTON_VISUALIZAR': 'Accept',
                    'INFO':{
                        'ID_RED_DE_PAGO': 'ID required for the cash transfer',
                        'NOMBRE_RED_DE_PAGO': 'The name of the payment network is required',
                        'CODIGO_RED_DE_PAGO': 'The payment network code is required',
                        'TIPO_RED_DE_PAGO': 'The type of payment network is required',
                        'TARIFA_RED_DE_PAGO': 'The payment network rate must have a maximum of 8 characters and is required'
                    
                    }
                }
            }
        },
        'BALANCEV2': {
            'BASE': {
                'TITULO': 'Payins Settlement Balance',
                'TABLE_COUNTRY': 'Country',
                'TABLE_MERCHANT': 'Merchant',
                'TABLE_TRANSACTION_STATE': 'State',
                'TABLE_TOTAL_DAY_TRANSACTIONS': 'Total day Tran. Qty.',
                'TABLE_TRANSACTION_DATE': 'Transaction Date',
                'TABLE_SETTLEMENT_DATE': 'Settlement Date',
                'TABLE_CURRENCY': 'Currency',
                'TABLE_PROCCESSED_TRANS_QTY': 'Processed Trans. Qty.',
                'TABLE_TPV_BILLINGS': 'TpvBlillings',
                'TABLE_FEE': 'Fee',
                'TABLE_TAX': 'Tax',
                'TABLE_NET_TO_MERCHANT': 'Net To Merchant',
                'TABLE_SETTLED_TRANS_QTY': 'Settled Trans. Qty.',
                'TABLE_SETTLEMENT_TYPE': 'Settlement Type',
                'TABLE_SETTLEMENT_CODE': 'Settlement Code',
                'TABLE_IN_PROCESS': 'In Process',
                'TABLE_DOWNLOAD': 'Download',
                'BOX_IN_PROCESS': 'Total In Process',
                'BOX_TOTAL_PROCESSED': 'Processed Total',
                'SUMMARY_TEXT': 'Summatory of all rows filtered',
                'SUMMATORY_TEXT_PRINT': 'Total Amounts:'
            }
        },
        'PAYMENT_LINK': {
            'BASE': {
                'GENERATION_TITLE': 'Payment Link Generation',
                'CONFIGURATION_TITLE': 'Configuration of Payment Links',
                'LIST_TITLE': 'List of Generated Links',
                'PROCESS_TITLE': 'Massive processing',
            },
            'PROCESS': {
                'FILTERS':{
                    'USER': 'User',
                    'STATE': 'State',
                    'INIT_DATE': 'Start Date',
                    'END_DATE': 'End Date',
                    'PLACEHOLDER': 'Write here...',
                },
                'ACTIONS':{
                    'CREATE': 'Create payment links',
                },
                'TABLE':{
                    'ID': 'ID',
                    'FILE_NAME': 'File name',
                    'USER': 'User',
                    'DATE': 'Registration date',
                    'DATE_PROCESS': 'Processing date',
                    'STATE': 'State',
                    'FILE_IN': 'Input file',
                    'TOOLTIP_FILE_IN': 'Here you can download the file you imported for your bulk payment links request.',	
                    'FILE_OUT': 'Output file',	
                    'TOOLTIP_FILE_OUT': 'Here you can download the file with the details of the bulk payment links.',
                },
                'DIALOG':{
                    'TITLE': 'Mass payment link',
                    'MESSAGE': 'Generate multiple payment links simultaneously by importing a .csv file using the <strong>"Import File"</strong> button.',
                    'ALERT': 'Download the <a>guide</a> on how to create the file for importing into the backoffice', 
                    'MIN_ALERT': '*Please note that the maximum number of records allowed is 500 per request',   
                    'IMPORT': 'Import file',
                    'DOWNLOAD_TEMPLATE': 'Download template',


                    'TITLE_UPLOAD': 'Upload file',
                    'IMPORTANT': 'Important!',
                    'LIST_1': 'Please note that the maximum number of records allowed is <strong>500 per request.</strong>',
                    'LIST_2':'After saving the file, you will not be able to make changes.',


                    'TITLE_DETAIL': 'File uploaded',
                    'SUCCESS_MESSAGE': 'You successfully imported the file:',
                    'ANSWER': 'How long will the file be available? Document processing time depends on the number of records. Stay tuned.',

                    'ERROR': `Oops! There's been a problem `,
                    'ERROR_SUBTITLE': 'An error occurred while uploading the file.',
                    'RETRY': 'Try again',

                    'CANCEL': 'Cancel',
                    'UPLOAD': 'Upload',
                    'UPLOAD_NEW_FILE': 'Upload new file',
                    'OK': 'OK'
                }
                }
        },
        'INPUT':{
            'PLACEHOLDER': 'Write here...',
        },
        'QUESTIONNAIRE': {
            'TITLE': 'Rate Your Experience',
            'SUBTITLE_1': 'Help us by taking our',
            'SUBTITLE_2': 'Satisfaction Survey NPS',
            'DATE': 'From September 1 to 30.',
            'ACTION': 'LET’S GET STARTED!>', 
        }

        
    }
};
