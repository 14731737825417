export const environment = {
    production: false,
    hmr       : false,
    baseUrl   : 'https://cert.payin.monnetpayments.com/',
    urlApiMsUserBack: 'https://cert.monnetpayments.com/ms-user-back/',
    urlApiMsOperationBack: 'https://cert.monnetpayments.com/ms-operation-back-rest/',
    urlApiMsOperationReturn: 'https://cert.monnetpayments.com/ms-operation-return-rest/',
    urlApiMsMerchantBack: 'https://cert.monnetpayments.com/ms-merchant-back',
    urlApiMsCommissionBack: 'https://cert.monnetpayments.com/ms-commission-back',
    urlApiMsProcessorRouting: 'https://cert.monnetpayments.com/ms-processor-routing/',
    urlApiMsMasterParameters: 'https://cert.monnetpayments.com/ms-master-parameters/',
    urlApiMsExperience: 'https://cert.monnetpayments.com/ms-experience-front/',
    freshDeskUrlApi: 'https://monnetpayments.freshdesk.com/api/v2/tickets',
    freshDeskApiKey: 'q1XUepz7IWG0UBt1o6G',
    succesMonnetUrl: 'https://cert.checkout.monnet.io/payment-in-progress',
    errorMonnetUrl: 'https://cert.checkout.monnet.io/payment-rejected',
    cognitoPool: {
        region: 'us-east-1',
        UserPoolId: "us-east-1_V7c1x5V3K",
        ClientId: "59omga4v8ckmbbvh3iic5egupi",
        identityPoolId: '',
        rekognitionBucket: 'rekognition-pics',
        albumName: "usercontent",
        bucketRegion: 'us-east-1',
        ddbTableName: 'LoginTrail',
        cognito_idp_endpoint: '',
        cognito_identity_endpoint: '',
        sts_endpoint: '',
        dynamodb_endpoint: '',
        s3_endpoint: ''
    },
    roles: {
        AdminMerchant: "1",
        VisorMerchant: "2",
        AdministradorMonnet: "3",
        VisorMonnet: "4",
        IntegradorMerchant: "5",
        AdministradorGeneralMonnet: "6",
        UsuarioLinkDePago: "7",
    },
    keycloak: {
        keycloakRealm: 'back-office-payin',
        keycloakUrl: 'https://cert.auth.monnet.io',
        keycloakClientId: 'payin-backoffice-cert',
        allowedOrigins: ['https://cert.payin.monnetpayments.com'],
        redirectUri: 'https://cert.payin.monnetpayments.com/apps/dashboards/project',
        client_secret: 'E7tfxQvgcktv7STyNfbB6LBfSp3SQgkZ',
        sessionExpired: 900000,//15 minutos 900000,
        urlOtp: 'https://cert.auth.monnet.io/realms/back-office-payin/protocol/openid-connect/auth?client_id=payin-backoffice-cert&redirect_uri=https%3A%2F%2Fcert.payin.monnetpayments.com%2Fapps%2Fdashboards%2Fproject&state=5fcc2ca5-f3f3-4285-8dd0-90ebbf121304&response_mode=fragment&response_type=code&scope=openid&nonce=14b04b10-74bb-454e-bb5a-0e3500f96216&kc_action=CONFIGURE_TOTP&code_challenge=5fN5M9Vz4FnTsTa1dvpUg-97RRyihsg-nY-WLs69o7I&code_challenge_method=S256'
    },
    merchantMonnet: 86,
    showByEnvironment: false,
};
